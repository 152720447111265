import React, {useEffect, useState} from 'react';
import style from "../orderItem/orderItem.module.scss";

const Selected = ({tabsBtn, status, changeStatus}) => {

    const [tabsBtnIndex, setTabsBtnIndex] = useState(0)
    let tabs = JSON.parse(JSON.stringify(tabsBtn))
    tabs.shift()

    useEffect(() => {
        tabs?.length>0 && tabs.forEach((tab, index)=>{
            if(tab?.status === status){
               setTabsBtnIndex(index)
            }
        })
    }, [status])



    useEffect(() => {
        if(changeStatus){
            changeStatus(tabsBtn[tabsBtnIndex+1].status)
        }
    }, [tabsBtnIndex]);

    return (
        <div className={style.selected} onClick={e => e.stopPropagation()}>
            <div className={style.body}>
                {
                    <>
                        <div className={style.selectedTitle}
                             style={{"--color": `${tabs[tabsBtnIndex]?.background}`}}>
                            {tabs[tabsBtnIndex]?.status}
                        </div>
                        <svg width="11" height="6" viewBox="0 0 11 6" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 1L5.5 5.5L10 1" stroke="#B0B0B0" strokeLinecap="round"
                                  strokeLinejoin="round"/>
                        </svg>
                    </>
                }
            </div>
            <div className={style.items}>
                {tabs.map((tab, index) =>
                    <div
                        key={tab?.status}
                        onClick={e => {
                            setTabsBtnIndex(index)

                        }}
                    >
                        {
                            <div className={style.selectItem} style={{"--color": `${tab?.background}`}}>
                                {tab?.status}
                            </div>

                        }
                    </div>
                )}
            </div>
        </div>
    );
};

export default Selected;