import React, {useContext, useEffect, useState} from 'react';
import style from "./orderItem.module.scss";
import Selected from "../selected/selected";
import {Context} from "../../../index";
import {observer} from "mobx-react-lite";

const OrderItem = ({order, setPopupData, popupData, tabsBtn}) => {

    const [item, setItem] = useState({})

    useEffect(() => {
        setItem(order)
    }, [order]);

    const {orders} = useContext(Context)
    const createdTimeOrder = new Date(item?.createdAt)

    const changeStatus = async (status) =>{
        await orders.update({...item, status})
    }



    return (
        <div className={style.item}
             onClick={e => {
                 e.stopPropagation()
                 setPopupData({visible: true, data: item})
             }}>
            <div>
                <div style={item?.time &&{color: "#2E50FF"}} className={style.itemTime}>{createdTimeOrder.getHours()}:{createdTimeOrder.getMinutes()<10 ? "0"+createdTimeOrder.getMinutes() : createdTimeOrder.getMinutes()}</div>
                <div style={item?.time &&{color: "#2E50FF"}} className={style.itemOrderNumber}>{item?.number}</div>
            </div>
            <div>
                {item?.status &&<Selected tabsBtn={tabsBtn} status={item?.status} changeStatus={changeStatus}/>}
            </div>
            <div>
                <div className={style.itemText}>{item?.user?.name}</div>
            </div>
            <div>
                <div className={style.itemText}>
                    {item?.address !== null
                        ? `г. ${item?.address?.city}, ул. ${item?.address?.street}, ${item?.address?.house}, подъезд ${item?.address?.city}`
                        : '-'
                    }
                </div>
            </div>
            <div>
                <div className={style.itemText}>{item?.user?.phone}</div>
            </div>
            <div>
                <div className={style.delivery} style={{"--color": item?.address === null ? "#222" : "#2e50ff"}}>
                    {item?.address === null
                        ? <>
                            <span>Самовывоз</span>
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M6.57878 0.0732405C6.91535 0.244233 7.05177 0.660084 6.88348 1.00207L4.1581 6.54053C3.98981 6.88251 3.58054 7.02113 3.24397 6.85014C2.9074 6.67915 2.77098 6.2633 2.93927 5.92131L5.66465 0.38285C5.83294 0.0408648 6.24221 -0.097752 6.57878 0.0732405ZM11.4201 0.0732405C11.7567 -0.097752 12.166 0.0408648 12.3343 0.38285L15.0596 5.92131C15.2279 6.2633 15.0915 6.67915 14.7549 6.85014C14.4184 7.02113 14.0091 6.88251 13.8408 6.54053L11.1154 1.00207C10.9471 0.660084 11.0836 0.244233 11.4201 0.0732405ZM0.141947 8.07707C0.141947 7.69472 0.446997 7.38477 0.823294 7.38477H4.45714C4.83344 7.38477 5.13849 7.69472 5.13849 8.07707C5.13849 8.45942 4.83344 8.76938 4.45714 8.76938H0.823294C0.446997 8.76938 0.141947 8.45942 0.141947 8.07707ZM7.40965 8.07707C7.40965 7.69472 7.7147 7.38477 8.09099 7.38477H17.1756C17.5519 7.38477 17.857 7.69472 17.857 8.07707C17.857 8.45942 17.5519 8.76938 17.1756 8.76938H8.09099C7.7147 8.76938 7.40965 8.45942 7.40965 8.07707ZM6.50118 10.8463C6.50118 10.464 6.80623 10.154 7.18253 10.154H10.8164C11.1927 10.154 11.4977 10.464 11.4977 10.8463C11.4977 11.2287 11.1927 11.5386 10.8164 11.5386H7.18253C6.80623 11.5386 6.50118 11.2287 6.50118 10.8463Z"
                                      fill="#222222"/>
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M14.852 5.19843C14.031 5.07862 12.9212 5.07692 11.342 5.07692H6.65793C5.07917 5.07692 3.96913 5.07862 3.14782 5.19843C2.34472 5.3156 1.95086 5.52894 1.69718 5.84799C1.44337 6.16656 1.32019 6.60283 1.37578 7.42534C1.43262 8.26644 1.6636 9.36961 1.99417 10.9382L2.3839 12.7843C2.60818 13.8502 2.76503 14.5886 2.95269 15.1407C3.13423 15.6748 3.32053 15.9574 3.55933 16.1534C3.79713 16.349 4.10827 16.4751 4.66045 16.5435C5.2311 16.6142 5.97413 16.6154 7.04675 16.6154H10.9531C12.0258 16.6154 12.7688 16.6142 13.3396 16.5435C13.8918 16.4751 14.2028 16.3495 14.4412 16.1536C14.6795 15.9578 14.8655 15.6753 15.0469 15.1409C15.2344 14.5886 15.3912 13.8505 15.616 12.7845C15.6948 12.4106 16.057 12.1725 16.4249 12.2526C16.7929 12.3327 17.0272 12.7007 16.9484 13.0746L16.9386 13.121C16.7255 14.1318 16.5526 14.9518 16.3351 15.5925C16.1082 16.2608 15.809 16.8105 15.2979 17.2304C14.7868 17.6502 14.1952 17.8324 13.5044 17.918C12.8416 18 12.0153 18 10.9967 18H7.00319C5.98458 18 5.15827 18 4.49556 17.918C3.80512 17.8324 3.21364 17.6503 2.70294 17.2304C2.19152 16.8107 1.89186 16.2612 1.66464 15.5927C1.44596 14.9493 1.27256 14.1252 1.05868 13.1088L0.648749 11.1667C0.333113 9.66913 0.0805203 8.47067 0.0162848 7.5202C-0.0500803 6.53822 0.0728585 5.68636 0.637389 4.9776C1.20131 4.26853 1.99519 3.96777 2.95416 3.82787C3.88368 3.69227 5.09199 3.69229 6.60239 3.69231H11.3976C12.9084 3.69229 14.1165 3.69227 15.0458 3.82788C16.0048 3.96783 16.7982 4.26868 17.3625 4.97756C18.1923 6.02212 18.08 7.39684 17.7643 9.12625C17.6956 9.50219 17.3401 9.75043 16.9701 9.6807C16.6001 9.61097 16.3558 9.24969 16.4244 8.87375C16.7536 7.07026 16.6901 6.33603 16.3029 5.84829M14.852 5.19843C15.6543 5.3155 16.0487 5.52914 16.3029 5.84829L14.852 5.19843Z"
                                      fill="#222222"/>
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M6.57878 0.0732405C6.91535 0.244233 7.05177 0.660084 6.88348 1.00207L4.1581 6.54053C3.98981 6.88251 3.58054 7.02113 3.24397 6.85014C2.9074 6.67915 2.77098 6.2633 2.93927 5.92131L5.66465 0.38285C5.83294 0.0408648 6.24221 -0.097752 6.57878 0.0732405ZM11.4201 0.0732405C11.7567 -0.097752 12.166 0.0408648 12.3343 0.38285L15.0596 5.92131C15.2279 6.2633 15.0915 6.67915 14.7549 6.85014C14.4184 7.02113 14.0091 6.88251 13.8408 6.54053L11.1154 1.00207C10.9471 0.660084 11.0836 0.244233 11.4201 0.0732405ZM0.141947 8.07707C0.141947 7.69472 0.446997 7.38477 0.823294 7.38477H4.45714C4.83344 7.38477 5.13849 7.69472 5.13849 8.07707C5.13849 8.45942 4.83344 8.76938 4.45714 8.76938H0.823294C0.446997 8.76938 0.141947 8.45942 0.141947 8.07707ZM7.40965 8.07707C7.40965 7.69472 7.7147 7.38477 8.09099 7.38477H17.1756C17.5519 7.38477 17.857 7.69472 17.857 8.07707C17.857 8.45942 17.5519 8.76938 17.1756 8.76938H8.09099C7.7147 8.76938 7.40965 8.45942 7.40965 8.07707ZM6.50118 10.8463C6.50118 10.464 6.80623 10.154 7.18253 10.154H10.8164C11.1927 10.154 11.4977 10.464 11.4977 10.8463C11.4977 11.2287 11.1927 11.5386 10.8164 11.5386H7.18253C6.80623 11.5386 6.50118 11.2287 6.50118 10.8463Z"
                                      fill="#222222"/>
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M14.852 5.19843C14.031 5.07862 12.9212 5.07692 11.342 5.07692H6.65793C5.07917 5.07692 3.96913 5.07862 3.14782 5.19843C2.34472 5.3156 1.95086 5.52894 1.69718 5.84799C1.44337 6.16656 1.32019 6.60283 1.37578 7.42534C1.43262 8.26644 1.6636 9.36961 1.99417 10.9382L2.3839 12.7843C2.60818 13.8502 2.76503 14.5886 2.95269 15.1407C3.13423 15.6748 3.32053 15.9574 3.55933 16.1534C3.79713 16.349 4.10827 16.4751 4.66045 16.5435C5.2311 16.6142 5.97413 16.6154 7.04675 16.6154H10.9531C12.0258 16.6154 12.7688 16.6142 13.3396 16.5435C13.8918 16.4751 14.2028 16.3495 14.4412 16.1536C14.6795 15.9578 14.8655 15.6753 15.0469 15.1409C15.2344 14.5886 15.3912 13.8505 15.616 12.7845C15.6948 12.4106 16.057 12.1725 16.4249 12.2526C16.7929 12.3327 17.0272 12.7007 16.9484 13.0746L16.9386 13.121C16.7255 14.1318 16.5526 14.9518 16.3351 15.5925C16.1082 16.2608 15.809 16.8105 15.2979 17.2304C14.7868 17.6502 14.1952 17.8324 13.5044 17.918C12.8416 18 12.0153 18 10.9967 18H7.00319C5.98458 18 5.15827 18 4.49556 17.918C3.80512 17.8324 3.21364 17.6503 2.70294 17.2304C2.19152 16.8107 1.89186 16.2612 1.66464 15.5927C1.44596 14.9493 1.27256 14.1252 1.05868 13.1088L0.648749 11.1667C0.333113 9.66913 0.0805203 8.47067 0.0162848 7.5202C-0.0500803 6.53822 0.0728585 5.68636 0.637389 4.9776C1.20131 4.26853 1.99519 3.96777 2.95416 3.82787C3.88368 3.69227 5.09199 3.69229 6.60239 3.69231H11.3976C12.9084 3.69229 14.1165 3.69227 15.0458 3.82788C16.0048 3.96783 16.7982 4.26868 17.3625 4.97756C18.1923 6.02212 18.08 7.39684 17.7643 9.12625C17.6956 9.50219 17.3401 9.75043 16.9701 9.6807C16.6001 9.61097 16.3558 9.24969 16.4244 8.87375C16.7536 7.07026 16.6901 6.33603 16.3029 5.84829M14.852 5.19843C15.6543 5.3155 16.0487 5.52914 16.3029 5.84829L14.852 5.19843Z"
                                      fill="#222222"/>
                            </svg>
                        </>

                        : <>
                            <span>Доставка</span>
                            <svg width="26" height="16" viewBox="0 0 26 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M5.35764 0C4.27214 0 3.26951 0.5344 2.66582 1.4248L0.406264 4.7504C0.140342 5.14253 -0.0010738 5.60369 1.39569e-05 6.0752V11.3752C-0.00148415 11.7303 0.11766 12.0758 0.338497 12.3565C0.559334 12.6373 0.869183 12.8374 1.21876 12.9248L3.32639 13.4504C3.47797 14.1694 3.87608 14.8152 4.45394 15.2794C5.03179 15.7436 5.75422 15.9981 6.50001 16C8.00476 16 9.26088 14.9752 9.62326 13.6H17.1893C17.5516 14.9752 18.8078 16 20.3125 16C21.8173 16 23.0734 14.9752 23.4358 13.6H24.375C25.2639 13.6 26 12.8752 26 12V9.0752C25.9992 8.36212 25.7564 7.66975 25.3103 7.10808C24.8641 6.54642 24.24 6.14767 23.5373 5.9752L19.1441 4.8752L15.9705 1.1504C15.6669 0.790702 15.2866 0.501181 14.8567 0.30237C14.4267 0.103559 13.9576 0.000329041 13.4826 0H5.35764ZM5.35764 1.6H8.93751V4.8H2.33595L4.01214 2.3248V2.3C4.15935 2.0823 4.35945 1.90424 4.59427 1.782C4.8291 1.65976 5.09202 1.5972 5.35764 1.6ZM10.5625 1.6H13.4826C13.9644 1.6 14.4186 1.8096 14.7266 2.1752L16.9609 4.8H10.5625V1.6ZM1.62501 6.4H18.5859L23.1562 7.5248C23.507 7.61049 23.818 7.81015 24.0392 8.0915C24.2604 8.37285 24.3787 8.71944 24.375 9.0752V12H23.4358C23.0734 10.6248 21.8173 9.6 20.3125 9.6C18.8078 9.6 17.5516 10.6248 17.1893 12H9.62326C9.26088 10.6248 8.00476 9.6 6.50001 9.6C5.05945 9.6 3.84964 10.5376 3.42795 11.8248L1.62501 11.376V6.4ZM6.50001 11.2C7.40757 11.2 8.12501 11.9064 8.12501 12.8C8.12501 13.6936 7.40757 14.4 6.50001 14.4C5.59245 14.4 4.87501 13.6936 4.87501 12.8C4.87501 11.9064 5.59245 11.2 6.50001 11.2ZM20.3125 11.2C21.2201 11.2 21.9375 11.9064 21.9375 12.8C21.9375 13.6936 21.2201 14.4 20.3125 14.4C19.4049 14.4 18.6875 13.6936 18.6875 12.8C18.6875 11.9064 19.4049 11.2 20.3125 11.2Z"
                                    fill="#2E50FF"/>
                            </svg>
                        </>
                    }

                </div>
            </div>
            <div>
                <div className={style.itemText}>{item?.cost}р</div>
            </div>
        </div>
    );
};

export default OrderItem;