import {$hostAuth} from "../http";

class OrderService{
    async getAll(selectDate){
        const {data} = await $hostAuth.get(`/api/order?date=${selectDate}`);
        return data
    }

    async update(orderData){
        await $hostAuth.put("/api/order/update", orderData)
        return this.getAll()
    }
}

export default new OrderService()