import {makeAutoObservable} from "mobx";
import StaffService from "../service/staffService";

export default class StaffStore {
    constructor() {
        this._isAuth = false
        this._staff = {}
        this._loading = false
        makeAutoObservable(this)
    }

    async login(login, password){
        this._loading = true
        const staffData = await StaffService.login(login, password)
        this._staff = staffData?.staff
        localStorage.setItem("accessToken", staffData?.accessToken)
        this._isAuth = true
        this._loading = false
    }

    async refresh(){
        this._loading = true
        const staffData = await StaffService.refresh()
        this._staff = staffData?.staff
        localStorage.setItem("accessToken", staffData?.accessToken)
        this._isAuth = true
        this._loading = false
    }

    get isAuth(){
        return this._isAuth
    }


    get staff(){
        return this._staff
    }
    get loading(){
        return this._loading
    }
}