import React from 'react';
import style from "../../popups/orderPopup.module.scss"

const ProductItem = ({product, indexProduct, deleteProduct, increment, decrement, decrementAddition, incrementAddition}) => {

    return (
        <div>
            <div className={style.productsItem}>
                <div className={style.productsRow}>
                    <div className={style.productsName}>{product?.title}</div>
                    <div className={style.productsActions}>
                        <div className={style.productsCounter}>
                            <button className={style.productsCounterBtn} onClick={e => decrement(indexProduct)}>
                                <svg width="13" height="2" viewBox="0 0 13 2" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.9082 1L0.999112 1" stroke="#CBCBCB" strokeWidth="2"
                                          strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </button>
                            <div className={style.productsCounterQty}>{product.qty}</div>
                            <button className={style.productsCounterBtn} onClick={e => increment(indexProduct)}>
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.64062 1.54297V12.4521" stroke="#CBCBCB" strokeWidth="2"
                                          strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M12.0938 6.99609L1.18466 6.99609" stroke="#CBCBCB"
                                          strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </button>
                        </div>
                        <div className={style.productsCost}>{product?.cost * product.qty}р</div>
                        <button className={style.productsDelete} onClick={e => deleteProduct(indexProduct)}>
                            <svg width="25" height="25" viewBox="0 0 25 25" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.4277 6.14844L6.14163 18.4345" stroke="#FF7070"
                                      strokeWidth="2"
                                      strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M18.4277 18.4297L6.14163 6.14358" stroke="#FF7070"
                                      strokeWidth="2"
                                      strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </button>
                    </div>
                </div>
                {product?.description !== "" &&
                    <div className={style.productsRow}>
                        <div className={style.productsDescription}>{product?.description}</div>
                    </div>
                }
                {
                    product?.additions?.length > 0 && product.additions.map((addition, index) =>
                        <div className={style.productsRow}>
                            <div className={style.productsAdditionName}>{addition?.title}</div>
                            <div className={style.productsActions}>
                                <div className={style.productsCounter}>
                                    <button className={style.productsCounterBtn}
                                            onClick={e => decrementAddition(indexProduct,  index)}>
                                        <svg width="11" height="2" viewBox="0 0 11 2" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10 1L1 1" stroke="#CBCBCB" strokeWidth="2" strokeLinecap="round"
                                                  strokeLinejoin="round"/>
                                        </svg>
                                    </button>
                                    <div className={style.productsAdditionCounterQty}>{addition.qty}</div>
                                    <button className={style.productsCounterBtn}
                                            onClick={e => incrementAddition(indexProduct, index)}
                                    >
                                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5 1V9" stroke="#CBCBCB" strokeWidth="2" strokeLinecap="round"
                                                  strokeLinejoin="round"/>
                                            <path d="M9 5L1 5" stroke="#CBCBCB" strokeWidth="2" strokeLinecap="round"
                                                  strokeLinejoin="round"/>
                                        </svg>
                                    </button>
                                </div>
                                <div className={style.productsCostAddition}>{addition?.cost * addition.qty}р</div>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default ProductItem;

