import {$hostPublic} from "../http"


class StaffService {
    async login(login, password) {
        const {data} = await $hostPublic.post("/api/staff/login", {login, password})
        return data
    }

    async refresh() {
        const {data} = await $hostPublic.get("/api/staff/refresh")
        return data
    }

}

export default new StaffService()