import React, {useState} from 'react';
import style from "./startPopup.module.scss"
const StartPopup = () => {

    const [visible, setVisible] = useState(true)
    if(visible){
        return (
            <div className={style.wrapper} onClick={e=>setVisible(false)}>
                <div className={style.body} onClick={e=>e.stopPropagation()}>
                    <div className={style.title}>Здравствуйте! Вы попали на страницу сбора заказов. Принимайте заказы и уточняйте их комплектацию удобно</div>
                    <button className={style.buttonStart} onClick={e=>setVisible(false)}>Начать работу</button>
                </div>
            </div>
        );
    }

};

export default StartPopup;