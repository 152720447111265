import {BrowserRouter} from "react-router-dom";
import AppRouter from "./components/AppRouter";
import "./scss/app.scss"
import {useContext, useEffect} from "react";
import {Context} from "./index";
function App () {
    const {staff} = useContext(Context)
    useEffect(()=>{
        async function fetchData() {
            try {
                await staff.refresh()
            }catch (e) {
                console.log(e)
            }
        }
        fetchData();
        // eslint-disable-next-line
    }, []);



    return (
        <div className="App" >
            <div className="wrapper">
                <BrowserRouter>
                    <AppRouter/>
                </BrowserRouter>
            </div>
        </div>
    );

}

export default App;
