import {makeAutoObservable} from "mobx";
import OrderService from "../service/orderService";
export default class OrderStore{
    constructor() {
        this._orders = []
        this._loading = false
        makeAutoObservable(this)
    }

    async getAll(selectDate){
        try{
            this._loading = true
            const fetch = await OrderService.getAll(selectDate);
            this._orders = fetch
            this._loading = false
            return fetch
        }catch (e) {
            this._loading = false
        }
    }

    async update(data){
        try{
            this._loading = true
            const fetch = await OrderService.update(data);
            this._orders = fetch
            this._loading = false
            return fetch
        }catch (e) {
            this._loading = false
        }
    }

    get orders(){
        return this._orders
    }
}



