import React, {useContext, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";

import style from "./login.module.scss"
import {Context} from "../../index";

const Login = () => {
    const {staff} = useContext(Context)
    const [form, setForm] = useState({login: "", password: ""});
    const [errorMessage, setErrorMessage] = useState("");

    const navigate = useNavigate();

    const loginForm = async (event) =>{
        event.preventDefault()
        try {
           await staff.login(form.login, form.password)
           navigate("/orders")
        }catch (e) {
            setErrorMessage(e?.response?.data?.message)
        }


    }
    if(staff.isAuth === true){
        navigate("/orders")
    }
    return (
        <div className="page">
            <div className="page_container">
                <div className={style.page}>
                    <div className={style.container}>
                        <div className={style.column}>
                            <div className={style.itemInfo}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="200" height="40" viewBox="0 0 200 40"
                                     fill="none">
                                    <path
                                        d="M53.8251 10.3125H45.4523L37.3395 24.8958L26.9386 10.3125L16.5376 24.8958L8.4248 10.3125H0L15.5495 38.1771L26.9386 22.1354L38.2756 38.1771L53.8251 10.3125Z"
                                        fill="white"/>
                                    <path
                                        d="M68.9971 38.6979C74.0936 38.6979 78.618 37.4479 81.4263 34.2708L75.9138 30.0521C74.9777 31.3542 71.6494 32.2396 69.7252 32.1875C66.1889 32.1875 63.2246 29.7917 62.8606 27.2917H81.4263C84.0266 16.875 77.578 9.58333 68.3211 9.53125C59.8443 9.47917 54.9038 16.5625 54.9038 24.0625C54.9038 31.25 60.5723 38.6979 68.9971 38.6979ZM62.8086 20.9896C62.8086 18.75 64.7327 15.9375 68.5811 15.9375C73.0535 15.9375 74.5617 19.1146 74.3016 20.9896H62.8086Z"
                                        fill="white"/>
                                    <path
                                        d="M101.965 10.1042C99.0527 10.1042 96.4005 11.4583 94.7883 13.4375V1.71875H87.5076V24.5833C87.5076 32.6042 94.0083 39.0625 101.965 39.0625C109.922 39.0625 116.422 32.6042 116.422 24.5833C116.422 16.6146 109.922 10.1042 101.965 10.1042ZM101.965 31.7708C98.0126 31.7708 94.7883 28.5938 94.7883 24.5833C94.7883 20.625 98.0126 17.3958 101.965 17.3958C105.917 17.3958 109.142 20.625 109.142 24.5833C109.142 28.5938 105.917 31.7708 101.965 31.7708Z"
                                        fill="white"/>
                                    <path
                                        d="M125.874 7.76042C128.006 7.76042 129.722 5.88542 129.722 3.85417C129.722 1.77083 128.006 0 125.874 0C123.794 0 122.026 1.77083 122.026 3.85417C122.026 5.88542 123.794 7.76042 125.874 7.76042ZM122.39 38.1771H129.306V10.3125H122.39V38.1771Z"
                                        fill="white"/>
                                    <path
                                        d="M137.267 38.1771H144.547V23.9063C144.547 19.5313 148.604 16.875 152.816 17.6042V10.3125C149.488 10.1562 145.483 11.0417 144.183 13.5417V10.3125H137.267V38.1771Z"
                                        fill="white"/>
                                    <path
                                        d="M171.314 38.4896C174.226 38.4896 176.775 37.1875 178.387 35.2604V38.6979H185.667V24.0625C185.667 16.1458 179.219 9.6875 171.314 9.6875C163.357 9.6875 156.909 16.1458 156.909 24.0625C156.909 32.0312 163.357 38.4896 171.314 38.4896ZM171.314 31.1979C167.362 31.1979 164.189 28.0208 164.189 24.0625C164.189 20.1563 167.362 16.9792 171.314 16.9792C175.214 16.9792 178.387 20.1563 178.387 24.0625C178.387 28.0208 175.214 31.1979 171.314 31.1979Z"
                                        fill="white"/>
                                    <path
                                        d="M196.152 7.76042C198.284 7.76042 200 5.88542 200 3.85417C200 1.77083 198.284 0 196.152 0C194.071 0 192.303 1.77083 192.303 3.85417C192.303 5.88542 194.071 7.76042 196.152 7.76042ZM192.667 38.1771H199.584V10.3125H192.667V38.1771Z"
                                        fill="white"/>
                                </svg>
                                <div>CMS</div>
                            </div>
                            <div className={style.itemInfo}>
                                <div>Система управления контентом разработанная командой webirai. Cильно облегчает
                                    работу людей, которые не хотят держать полноценный штат кодеров, но хотят
                                    поддерживать свой сайт, регулярно дополнять его новым контентом
                                </div>
                            </div>
                        </div>
                        <div className={style.column}>
                            <div className={style.body}>
                                <div className={style.title}>Добро пожаловать</div>
                                <div className={style.sybTitle}>Пожалуйста авторизуйтесь для входа в систему</div>
                                <form className={style.form} onSubmit={e => loginForm(e)}>
                                    <input type="text" value={form.email} name="email" placeholder="Логин"
                                           onChange={(e) => setForm({...form, login: e.target.value})}/><br/>
                                    <input type="password" value={form.password} name="password" placeholder="Пароль"
                                           onChange={(e) => setForm({...form, password: e.target.value})}/>
                                    <div className={style.formRow}>
                                        <button type="submit" className={style.formBtn}>Войти</button>
                                    </div>
                                    <div className={style.error}>{errorMessage}</div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;