import Login from "./pages/login/login";
import Orders from "./pages/orders/orders";
export const authRoutes = [
    {
        path: '/orders',
        Component: Orders
    },
    {
        path: '*',
        Component: Orders
    },
]

export const publicRoutes = [
    {
        path: '/login',
        Component: Login
    },{
        path: '*',
        Component: Login
    }
]