import React, {useContext, useEffect, useState} from 'react';
import Nav from "../../components/ui/nav/nav";

import style from "./orders.module.scss"

import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import OrderPopup from "../../components/popups/orderPopup";
import OrderItem from "../../components/ui/orderItem/orderItem";
import StartPopup from "../../components/popups/startPopup";
import {io} from "socket.io-client";
import useSound from "use-sound";
import newOrderSound from "../../sounds/newOrder.mp3";


const Orders = observer(() => {
    const [currentTab, setCurrenTab] = useState(0)
    const {orders} = useContext(Context)
    const [selectDate, setSelectDate] = useState(new Date().toISOString().split('T')[0])
    const [ordersData, setOrdersData] = useState([])
    const [popupData, setPopupData] = useState({visible: false, data: {}})
    const tabsBtn = [
        {
            title: "Все",
            background: "#D2D2D2",
            status: "Все"
        },
        {
            title: "Новые",
            background: "#C3EABD",
            status: "Новый",
            color: "#1DCC00"
        },
        {
            title: "В работе",
            background: "#EAE5BD",
            status: "В работе",
            color: "#E1CE39"
        },
        {
            title: "Завершенные",
            background: "#D2D2D2",
            status: "Завершен",
            color: "#B0B0B0"
        },
        {
            title: "Отменен",
            background: "#E39797",
            status: "Отменен",
            color: "#E39797"
        }
    ]

    useEffect(() => {
        const fetch = async() =>{
            await orders.getAll(selectDate)
        }
        fetch()
    }, [selectDate]);

    const socket = io("wss://api.tailao.ru");
    const [play] = useSound(newOrderSound);

    socket.on("newOrder", async ()=>{
        try {
            play()
            await orders.getAll(selectDate)
        }catch (e) {
            console.log(e)
        }
    })

    useEffect(() => {
        if(currentTab!==0) {
            const filterOrders = orders?.orders?.orders.filter(order => order?.status === tabsBtn[currentTab].status)
            setOrdersData(filterOrders)
        }else{
            setOrdersData(orders.orders.orders)
        }
    }, [currentTab, orders.orders.orders]);


    return (
        <div className="page">
            <div className="page_container">
                <div className={style.block}>
                    <Nav title="Заявки">
                        <div className={style.body}>
                            <div className={style.row}>
                                <div className={style.tabs}>
                                    {tabsBtn.map((tab, index) =>
                                        <button
                                            key={tab.title}
                                            onClick={e=>setCurrenTab(index)}
                                            className={currentTab === index ? [style.tabBtn, style.tabBtnActive].join(" ") : style.tabBtn}
                                            style={{"--color": `${tab.background}`}}
                                        >
                                            {tab.title}
                                        </button>
                                    )}
                                </div>
                                <div className={style.today}>
                                    <input value={selectDate} type="date" onChange={e=>setSelectDate(e.target.value)} className={style.dateInput}/>
                                </div>
                            </div>

                            <div className={style.table}>
                                <div className={style.tableHeader}>
                                    <div>Заказ</div>
                                    <div>Статус</div>
                                    <div>Имя</div>
                                    <div>Адрес</div>
                                    <div>Телефон</div>
                                    <div>Тип</div>
                                    <div>Сумма</div>
                                </div>
                                <div className={style.tableBody}>
                                    {ordersData?.length > 0 && ordersData.map(item =>
                                        <OrderItem key={item?.id} order={item} setPopupData={setPopupData} popupData={popupData} tabsBtn={tabsBtn}/>
                                    )}
                                </div>
                                <div className={style.tableFooter}>
                                    <div>Всего: {ordersData?.length}</div>
                                    <div>Сумма: {orders.orders.cost}р</div>
                                </div>
                            </div>
                        </div>

                    </Nav>
                </div>
                <OrderPopup popupData={popupData} setPopupData={setPopupData} tabsBtn={tabsBtn}/>
                <StartPopup/>
            </div>

        </div>
    );
});

export default Orders;