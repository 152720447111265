import axios from "axios";

export const API_URL = 'https://api.tailao.ru'

const url = "https://cms.tailao.ru"

const $hostPublic = axios.create({
    baseURL: API_URL,
    withCredentials: true,
    origin: url,
    credentials: 'include',
})

const $hostAuth = axios.create({
    baseURL: API_URL,
    withCredentials: true,
    origin: url,
    credentials: 'include'
})

$hostAuth.interceptors.request.use((config)=>{
    config.headers.Authorization = `Bearer ${localStorage.getItem("accessToken")}`
    return config
})


$hostAuth.interceptors.response.use((config)=>{
    return config
}, (async (error) => {
    const originalRequest = error.config
    if (error.response.status === 401) {
        try {
            const {data} = await axios.get(`${API_URL}/api/staff/refresh`, {withCredentials: true})
            localStorage.setItem("accessToken", data.accessToken)
            return $hostAuth.request(originalRequest)
        }catch (e) {
            console.log("Пользователь не авторизован")
        }
        
    }
}))

export {
    $hostPublic,
    $hostAuth
}